.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.extraButton {
	position: absolute;
	left: 100px;
	top: 170px;
}

.admin-container {
	padding-top: 20px;
	border-top: 0.5px solid #dedede;
}

.admin-dash-buttons-ref {
	margin-top: 30px;
	padding: 30px 120px 30px 30px;
}

.admin-dash-buttons-ref label {
	min-width: 300px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
}
.admin_extra_button {
	width: auto !important;
	margin-left: 20px;
}

.admin-dash-buttons-ref label button {
	width: 200px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media (max-width: 768px) {
	.extraButton {
		left: 50px;
		top: 250px;
	}
	.player-nested {
		flex-direction: column;
		width: 97%;
	}
	.player-block {
		width: 100% !important;
	}
	.player-block img {
		width: auto;
	}
	.rhap_play-pause-button {
		font-size: 80px !important;
		width: 80px !important;
		height: 80px !important;
	}
	.rhap_main-controls-button {
		font-size: 70px !important;
		width: 70px !important;
		height: 70px !important;
	}
	.song_list {
		margin-bottom: 250px !important;
	}
}
